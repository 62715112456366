import { getMsalInstance } from './initializeMsalLibrary';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * The sesssion id needs to be stored as a cookie for the Federated logout to work. -- Needed for Federated Logout. Removal tracked by https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/346726
 *	> 'owa-config/lib/universalCookies' import is restricted from being used. The long term plan is to deprecate the cookie so we should only be using this sparingly */
import { getCookie } from 'owa-config/lib/universalCookies';
import type { MailboxInfo } from 'owa-client-types';

export default async function onFederatedLogout(
    response: Response,
    mailboxInfo: MailboxInfo
): Promise<Response | undefined> {
    if (mailboxInfo && response) {
        const logoutSidCookie = getCookie('logoutSid');
        const logoutSidHeader = response.headers?.get('x-Owa-Logoutsid');

        if (logoutSidCookie || logoutSidHeader) {
            const msalInstance = await getMsalInstance();
            const accountSid = msalInstance?.getActiveAccount()?.idTokenClaims?.sid;
            const logoutSid = isConsumer(undefined, mailboxInfo)
                ? logoutSidCookie
                : logoutSidHeader;
            if (logoutSid === accountSid) {
                var logoutUrl = new URL(response.url);
                logoutUrl.pathname = '/mail/logoutFrame.html';
                logoutUrl.search = 'loadMoreIframes=false&sid=' + accountSid;
                self.location.assign(logoutUrl.toString());

                return Promise.reject<Response>(response);
            }
        }
    }

    return undefined;
}
